export const color = {
    'background': '',
    'leftCardBackground': '#F4F5F7',
    'rightCardBackground': '#F0F1F3',
    'warningCardBackground': '#F84F52', 
    'radioButton': '', 
    'event': '#73CAEC', 
    'eventBorder': '#73CAEC',
    'eventText': '#000',
    'selectedEvent': '#027FF9', 
    'today': '',
    'noMorePlaces': '#F5BA7C',
    'noMorePlaceText': '#000',
    'noMorePlaceBorder': '#F79F46'
}

export const niiomr_ru = 'omr.by'
export const niimor_by = 'omr.by'
export const niiomr_eng = 'omr.by'
// export const niiomr_ru = 'РНПЦ ОМР им. Н.Н. Александрова'
// export const niimor_by = 'РНПЦ АМР им. М.М. Аляксандрава'
// export const niiomr_eng = 'N.N. Alexandrov National Cancer Centre'

export const where_to_go_ru = 'Куда ехать?'
export const where_to_go_by = 'Куды ехаць?'
export const where_to_go_eng = 'Where to go?'

export const make_an_appointment_ru = 'Записаться на ПЦР-тест'
export const make_an_appointment_by = 'Запісацца на ПЦР-тэст'
export const make_an_appointment_eng = 'Make an appointment'

export const state_institution_ru = 'Государственное Учреждение'
export const state_institution_by = 'Дзяржаўная Ўстанова'
export const state_institution_eng = 'State Institution'

export const state_institution_name_ru = 'Республиканский научно-практический центр онкологии и медицинской радиологии им. Н.Н. Александрова'
export const state_institution_name_by = 'Рэспубліканскі навукова-практычны цэнтр анкалогіі і медыцынскай радыялогіі імя М.М. Аляксандрава'
export const state_institution_name_eng = 'N. N. Alexandrov National Cancer Centre of Belarus'

export const rmglk_ru = 'Республиканская молекулярно-генетическая лаборатория канцерогенеза'
export const rmglk_by = 'Рэспубліканская малекулярна-генетычная лабараторыя канцэрагенеза'
export const rmglk_eng = 'National Molecular Genetics Laboratory of Cancer Research '

export const step_1_ru = 'Выберите дату'
export const step_1_by = 'Выберыце дату'
export const step_1_eng = 'Choose date'

export const step_2_ru = 'Заполните форму'
export const step_2_by = 'Запоўніце форму'
export const step_2_eng = 'Fill the form'

export const step_3_ru = 'Подтверждение записи'
export const step_3_by = 'Пацвярджэнне запісу'
export const step_3_eng = 'Appointment confirmation'

export const attention_ru = 'Внимание!'
export const attention_by = 'Увага!'
export const attention_eng = 'Attention!'

export const attention_data_line1_ru = 'При заборе материала для выполнения ПЦР-теста в '
export const attention_data_line1_by = 'Пры выкананні тэсту ў '
export const attention_data_line1_eng = 'When the test is performed on '

export const attention_data_saturday_ru = 'субботу'
export const attention_data_saturday_by = 'суботу'
export const attention_data_saturday_eng = 'Saturday'

export const attention_data_line2_ru = ' результат анализа выдаётся в '
export const attention_data_line2_by = ' вынік выдаецца ў '
export const attention_data_line2_eng = " the result is issued on "

export const attention_data_on_monday_ru = 'понедельник после 14:00'
export const attention_data_on_monday_by = 'панядзелак'
export const attention_data_on_monday_eng = 'Monday'

export const contact_developers_ru = 'Связаться с разработчиками'
export const contact_developers_by = 'Звязацца з распрацоўшчыкамі'
export const contact_developers_eng = 'Contact the developers'

export const click_to_register_ru = 'Нажмите чтобы записаться' 
export const click_to_register_by = 'Націсніце каб запісацца ' 
export const click_to_register_eng = 'Click to register' 

export const click_to_register_saturday_ru = 'Нажмите чтобы записаться | Результат в ПН!' 
export const click_to_register_saturday_by = 'Націсніце каб запісацца | Вынік у ПН!' 
export const click_to_register_saturday_eng = 'Click to register | Results on Mon!' 
 
export const form_first_name_ru = 'Имя'
export const form_first_name_by = 'Імя'
export const form_first_name_eng = 'First name'

export const form_last_name_ru = 'Фамилия'
export const form_last_name_by = 'Прозвішча'
export const form_last_name_eng = 'Last name'

export const form_middle_name_ru = 'Отчество'
export const form_middle_name_by = 'Iмя па бацьку'
export const form_middle_name_eng = 'Middle name'

export const form_birth_date_ru = 'Дата рождения'
export const form_birth_date_by = 'Дата нараджэння'
export const form_birth_date_eng = 'Date of birth'

export const form_gender_ru = 'Пол'
export const form_gender_by = 'Пол'
export const form_gender_eng = 'Sex'

export const form_gender_male_ru = 'Мужской'
export const form_gender_male_by = 'Мужчынскі'
export const form_gender_male_eng = 'Male'

export const form_gender_female_ru = 'Женский'
export const form_gender_female_by = 'Жаночы'
export const form_gender_female_eng = 'Female'

export const form_city_ru = 'Город / Населённый пункт'
export const form_city_by = 'Горад / Населены пункт'
export const form_city_eng = 'City'

export const form_address_ru = 'Адрес'
export const form_address_by = 'Адрас'
export const form_address_eng = 'Address'

export const form_phone_ru = 'Контактный телефон'
export const form_phone_by = 'Кантактны тэлефон '
export const form_phone_eng = 'Phone'

export const form_confirm_btn_txt_ru = 'Записаться'
export const form_confirm_btn_txt_by = 'Запісацца'
export const form_confirm_btn_txt_eng = 'Register'

export const form_saturday_attention_ru = 'Результат будет выдан в понедельник!'
export const form_saturday_attention_by = 'Вынік будзе выдадзены ў панядзелак!'
export const form_saturday_attention_eng = 'The result is issued on Monday!'

export const confirm_appointment_confirmation_ru = 'Подтверждение online-записи'  
export const confirm_appointment_confirmation_by = 'Пацвярджэнне online-запісы'  
export const confirm_appointment_confirmation_eng = 'Appointment confirmation'  

export const confirm_sarscov2_ru = 'ПЦР-тестирование на наличие инфекции'
export const confirm_sarscov2_by = 'ПЦР-тэставанне на наяўнасць інфекцыі'
export const confirm_sarscov2_eng = 'PCR testing for SARS-Cov-2 infection'

export const confirm_we_are_waiting_ru = 'Ждём Вас '
export const confirm_we_are_waiting_by = 'Чакаем Вас '
export const confirm_we_are_waiting_eng = 'We are waiting for you '

export const confirm_we_are_waiting_at_ru = ' в '
export const confirm_we_are_waiting_at_by = ' ў '
export const confirm_we_are_waiting_at_eng = ' at '

export const confirm_reset_ru = 'В начало'
export const confirm_reset_by = 'У пачатак'
export const confirm_reset_eng = 'Back'

export const confirm_download_ru = 'Скачать'
export const confirm_download_by = 'Спампаваць'
export const confirm_download_eng = 'Download'

export const confirm_print_ru = 'Печать'
export const confirm_print_by = 'Друк'
export const confirm_print_eng = 'Print'