import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { 
  TextField, Box, Grid, Button, RadioGroup, 
  FormControl, FormControlLabel, Checkbox, Radio,
  FormLabel, Card, Container } from '@material-ui/core'
import { isBrowser } from 'react-device-detect'
import PhoneInput from 'react-phone-input-2'
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonIcon from '@material-ui/icons/Person';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import moment from 'moment'

import { 
  form_first_name_ru, form_first_name_by, form_first_name_eng,
  form_last_name_ru, form_last_name_by, form_last_name_eng,
  form_middle_name_ru, form_middle_name_by, form_middle_name_eng,
  form_birth_date_ru, form_birth_date_by, form_birth_date_eng,
  form_gender_ru, form_gender_by, form_gender_eng,
  form_gender_male_ru, form_gender_male_by, form_gender_male_eng,
  form_gender_female_ru, form_gender_female_by, form_gender_female_eng,
  form_city_ru, form_city_by, form_city_eng,
  form_address_ru, form_address_by, form_address_eng,
  form_confirm_btn_txt_ru, form_confirm_btn_txt_by, form_confirm_btn_txt_eng,
  form_saturday_attention_ru, form_saturday_attention_by, form_saturday_attention_eng
} from '../constants'

import ruLocale from 'react-phone-input-2/lang/ru.json'
import 'react-phone-input-2/lib/material.css'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(0.5),
      borderColor: 'black'
    },
  },
}));


export default function Form(props) {
  const classes = useStyles();
  const {
    firstName, setFirstName,
    lastName, setLastName, 
    middleName, setMiddleName, 
    birthDate, setBirthDate, 
    gender, handleGenderChange, 
    city, setCity, 
    address, setAddress, 
    phone, setPhone,
    ru, by
  } = props

  const [isAgree, setIsAgree] = React.useState(false);
  const [selCountryExpectedLength, setSelCountryExpectedLength] = React.useState(0)
  const [isValid, setIsValid] = React.useState(false) 
   
  const handleSubmit = (event) => {
    event.preventDefault(); 
      if (isValid) {
        props.createNewScheduledPatient();
        props.handleNext();
      };
  }
    
  return (
    <Container component="main" maxWidth="sm">
      <Card style={{ backgroundColor: '#F4F5F7', marginBottom: '3em'}}>
        <Box pt={1} pl={2} pr={2} pb={4} >
          <Box pl={1} pr={1} pt={1}>
            <Grid container>
              <Grid align="left" item xs={10} md={10}>
                <h4>
                  {moment(props.selectedDate).format('DD.MM.YYYY')}
                  &nbsp;({props.selectedDayOfWeek}) 
                </h4> 
              </Grid>
              <Grid align="right" item xs={2} md={2}>
                <CloseIcon 
                  style={{ 
                    cursor: 'pointer'
                  }}
                  onClick={() => {props.handleBack();}}/>
              </Grid>
            </Grid>
          </Box>
          <div style={{ 
            backgroundColor: 'white', 
            borderColor: 'black', 
            borderStyle: 'solid', 
            borderWidth: "1px", 
            paddingBottom: '1.5em', 
            marginBottom:'-0.6em'
            }}
          >
          <Box pl={2} pt={2} pr={2}>
            <form 
              className={classes.root}
              onSubmit={(event) => {handleSubmit(event)}}
            >
            <Grid container spacing={1}>
              <Grid align="center" item md={6}>
                <TextField 
                  style={{
                    backgroundColor: 'white', 
                    borderColor: 'red'
                  }} 
                  placeholder={ 
                    (ru)  ? form_last_name_ru
                          : (by) ? form_last_name_by
                                 : form_last_name_eng
                  }
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon fontSize="middle"/>
                      </InputAdornment>
                     ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> 
                <TextField 
                  style={{backgroundColor: 'white'}} 
                  placeholder={
                    (ru)  ? form_first_name_ru
                          : (by)  ? form_first_name_by
                                  : form_first_name_eng
                  }
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon fontSize="middle"/>
                      </InputAdornment>
                     ),
                  }}
                  InputLabelProps={{
                      shrink: true,
                  }}
                /> 
                <TextField 
                  style={{backgroundColor: 'white'}} 
                  placeholder={
                    (ru)  ? form_middle_name_ru
                          : (by) ?  form_middle_name_by
                                 :  `${form_middle_name_eng} (if applicable)`
                  }
                  value={middleName}
                  onChange={(event) => setMiddleName(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon fontSize="middle"/>
                      </InputAdornment>
                     ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> 
              </Grid>
              <Grid align="left" item xs={12} md={6}>
                <TextField 
                  style={{backgroundColor: 'white'}} 
                  label={
                    (ru)  ? form_birth_date_ru
                          : (by)  ? form_birth_date_by
                                  : form_birth_date_eng
                  }
                  value={birthDate}
                  onChange={(event) => setBirthDate(event.target.value)}
                  variant="outlined"
                  type="date"
                  size="small"
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> 
                <Box pl={1}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {
                        (ru)  ? form_gender_ru
                              : (by)  ? form_gender_by
                                      : form_gender_eng
                      }
                    </FormLabel>
                    <RadioGroup 
                      aria-label="gender" 
                      name="gender" 
                      value={gender} 
                      onChange={handleGenderChange}
                    >
                      <FormControlLabel 
                        value="male" 
                        control={<Radio style={{ color: '#3f51b5'}} />} 
                        label={
                          (ru)  ? form_gender_male_ru
                                : (by)  ? form_gender_male_by 
                                        : form_gender_male_eng
                        } 
                      />
                      <FormControlLabel 
                        value="female" 
                        control={<Radio style={{ color: '#3f51b5'}} />} 
                        label={
                          (ru)  ? form_gender_female_ru
                                : (by)  ? form_gender_female_by
                                        : form_gender_female_eng
                        } 
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid align="center" item md={12}>
                <TextField 
                  style={{backgroundColor: 'white'}} 
                  placeholder={
                    (ru)  ? form_city_ru
                          : (by)  ? form_city_by
                                  : form_city_eng
                  }
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationCityIcon fontSize="middle"/>
                      </InputAdornment>
                     ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> 
                <TextField 
                  style={{backgroundColor: 'white'}} 
                  placeholder={
                    (ru)  ? form_address_ru
                          : (by)  ? form_address_by
                                  : form_address_eng
                  }
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationCityIcon fontSize="middle"/>
                      </InputAdornment>
                     ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> 
                <PhoneInput
                  country={'by'}
                  localization={ruLocale}
                  preferredCountries={['by', 'ru', 'ua']}
                  countryCodeEditable={false}
                  copyNumbersOnly={false}
                  enableSearch
                  placeholder="Выберите страну из списка повторно"
                  searchPlaceholder="Поиск"
                  searchNotFound="Ничего не найдено"
                  enableAreaCodeStretch
                  specialLabel=""
                  buttonStyle={{
                    paddingBottom: '0.5em'
                  }}
                  inputStyle={{  
                    height: '2em', 
                    marginLeft: '0.2em', 
                    marginTop:'-1em', width: '100%'
                  }}
                  containerStyle={{
                    marginTop: '1em'
                  }}
                  value={phone}
                  onChange={(inputPhone, countryData) => {
                      setPhone(`+${inputPhone}`);
                      setSelCountryExpectedLength(countryData.format.
                        replace(/ /g, "").replace(/-/g, "").
                          replace("(", "").replace(")", "").length)
                  }}
                  onBlur={() => {
                    (phone.length != selCountryExpectedLength) 
                      ? setIsValid(false) 
                      : setIsValid(true)
                  }}
                  isValid={() => 
                    (!isValid) 
                      ? phone.length == selCountryExpectedLength 
                      : isValid} 
                  inputProps={{
                    name: 'phone',
                    required: true,
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <hr/>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAgree}
                      onChange={() => setIsAgree(!isAgree)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={
                    <small>
                      {
                        (ru) ? <>Я даю согласие на обработку моих персональных данных:<br/>(фамилия, имя, отчество, дата рождения, город / населённый пункт, адрес, контактный телефон) для оформления лабораторного исследования ПЦР тестирования</>
                            : (by) ? <>Я даю згоду на апрацоўку маіх асабістых дадзеных: <br/>(прозвішча, імя, імя па бацьку, дата нараджэння, горад / населены пункт, адрас, кантактны тэлефон) для афармлення лабараторнага даследавання ПЦР тэставання </>
                                    : <>I consent to the processing of my personal data:<br/>(last name, first name, middle name, date of birth, city, address, contact phone number) for registration of laboratory research PCR testing</>
                      }
                    </small>
                  }
                />
                <hr/>
              </Grid>
              <Grid align="center" item md={4}>
                {
                  (props.isSaturday) && (isBrowser)
                    ? <span style={{ marginBottom: '-2', color: 'red' }}>
                        <small>
                          {
                            (ru)  ? form_saturday_attention_ru
                                  : (by)  ? form_saturday_attention_by
                                          : form_saturday_attention_eng
                          }
                        </small>
                       </span>
                    : null
                }
              </Grid>
              <Grid align="center" item xs={12} md={4}>
                {
                  (isAgree) 
                    ? <Button 
                        type="submit" 
                        variant="outlined"
                        color="primary"
                      >
                        {
                          (ru)  ? form_confirm_btn_txt_ru
                                : (by)  ? form_confirm_btn_txt_by
                                        : form_confirm_btn_txt_eng
                        }
                      </Button>
                    : <Button type="submit" variant="outlined" disabled>
                        {
                          (ru)  ?  form_confirm_btn_txt_ru
                                :  (by) ? form_confirm_btn_txt_by
                                        : form_confirm_btn_txt_eng
                        }
                      </Button>
                }
              </Grid>
              <Grid align="center" item md={4}>
                {
                  (props.isSaturday)
                    ? <span style={{ color: 'red', lineHeight: '0.5' }}>
                        <small>
                          {
                            (ru)  ? form_saturday_attention_ru
                                  : (by)  ? form_saturday_attention_by
                                          : form_saturday_attention_eng
                          }
                        </small>
                      </span>
                    : null
                }
              </Grid>
            </Grid>
            </form>
          </Box>
          </div>
        </Box>
      </Card>
    </Container>
  )
}
