'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var byLocale = {
  code: 'be',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: 'Папярэд',
    next: 'Наст',
    today: 'Сёння',
    month: 'Месяц',
    week: 'Тыдзень',
    day: 'Дзень',
    list: 'Парадак дня',
  },
  weekText: 'Тыд',
  allDayText: 'Увесь дзень',
  moreLinkText: function(n) {
    return '+ яшчэ ' + n
  },
  noEventsText: 'Няма падзей для адлюстравання',
};

exports.default = byLocale;

