import { color } from '../../constants'
import { isBrowser } from 'react-device-detect';

const fillInScheduledList = (ru_var, by_var, eng_var, schedule) => {

    let scheduledListVar = [];
    let placesString = '';
    let noPlacesString = '';
    let tabStringPlaces = '';
    let tabStringNoPlaces = '';

    if (isBrowser) {
      if (ru_var) {
        tabStringPlaces = '\xa0'
        placesString = 'Доступно';
        tabStringNoPlaces = '\xa0'
        noPlacesString = 'Нет мест'
      };
      
      if (by_var) {
        tabStringPlaces = '\xa0'
        placesString = 'Даступна';
        tabStringNoPlaces = '\xa0\xa0\xa0\xa0'
        noPlacesString = 'Пуста'
      };
      
      if (eng_var) {
        tabStringPlaces = '\xa0'
        placesString = 'Available';
        tabStringNoPlaces = '\xa0'
        noPlacesString = 'No places'
      };
    } else {
      tabStringPlaces = '\xa0\xa0'
    }

    for(let i = 0; i < schedule.length; i++) {
        if (schedule[i].places > 0) {
          scheduledListVar.push(
            { date: schedule[i].date, allDay: true, 
              title: `${tabStringPlaces}${placesString}`, 
              backgroundColor: color['event'], 
              borderColor: color['eventBorder'],
              textColor: color['eventText']  },
            )
          } else {
          scheduledListVar.push(
            { date: schedule[i].date, 
              allDay: true, title: `${tabStringNoPlaces}${noPlacesString}`, 
              backgroundColor: color['noMorePlaces'], 
              borderColor: color['noMorePlaceBorder'], 
              textColor: color['noMorePlaceText'] },
            )
        }
    }
    return scheduledListVar
  } 

  export default fillInScheduledList