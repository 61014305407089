import React from 'react'
import { Box, Card, Grid, Container } from '@material-ui/core'
import FullCalendar from '@fullcalendar/react'
import ruLocale from '@fullcalendar/core/locales/ru'
import enLocale from '@fullcalendar/core/locales/en-gb'
import byLocale from './by_locale/by' //self made locale for Belarusian language
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'; 
import tippy from "tippy.js";
import moment from 'moment'

import {BrowserView, MobileView} from 'react-device-detect';

import 'tippy.js/dist/tippy.css';
import '../layout/layout.css'

import { 
  color, click_to_register_ru, click_to_register_by, click_to_register_eng,
  click_to_register_saturday_ru, click_to_register_saturday_by, 
  click_to_register_saturday_eng,
  } from '../constants';


export default function DesktopCalendar(props) {
  const { 
    setSelectedDate, 
    scheduledList,
    ru, by, eng } = props

  const handleDateClick = (info) => {
    if (info.event._def.ui.backgroundColor != color['noMorePlaces']) {
      info.el.style.backgroundColor = color['selectedEvent']
      let currentDate = moment(info.event._instance.range.start);
      props.handleNext();
      setSelectedDate(currentDate.format('YYYY-MM-DD'))
      props.setDayOfWeek(currentDate.day())
      props.setDayOfWeekName(currentDate.day(), ru, by, eng) 
    }
  }

  const handleMouseEnter = (info) => {
    if (info.event._def.ui.backgroundColor != color['noMorePlaces'])  {
      if (info.el.fcSeg.lastCol === 5) {
        if (ru) {
          tippy(info.el, {content: click_to_register_saturday_ru});  
        };
        if (by) {
          tippy(info.el, {content: click_to_register_saturday_by});  
        };
        if (eng) {
          tippy(info.el, {content: click_to_register_saturday_eng});  
        };
      }
      else {
        if (ru) {
          tippy(info.el, {content: click_to_register_ru});  
        };
        if (by) {
          tippy(info.el, {content: click_to_register_by});  
        };
        if (eng) {
          tippy(info.el, {content: click_to_register_eng});  
        };
      }
      info.el.style.cursor = 'pointer';
      info.el.style.backgroundColor = color['selectedEvent'];     
    }
  }

  const handleMouseLeave = (info) => {
    if (info.event._def.ui.backgroundColor != color['noMorePlaces']) {
          info.el.style.backgroundColor = color['event'];               
    }
  }
  
  return (
    <>
      <BrowserView>
        <Container component="main" maxWidth="md">
          <Grid container spacing={1}>
            <Grid item md={8}>
              <Box mt={2} mb={5} mr={2} >
                <Card style={{backgroundColor: color['leftCardBackground'] }}>
                  <Box m={4}>
                    <FullCalendar
                      locale={
                        (ru) 
                        ? ruLocale
                        : (by)
                        ? byLocale
                        : enLocale
                      }
                      plugins={[ interactionPlugin, dayGridPlugin ]}
                      headerToolbar={{ 
                        left: 'title', 
                        center: '',
                        right: 'prev,next'
                      }}
                      initialView="dayGridMonth"
                      selectable={false}
                      events={scheduledList}
                      eventMouseEnter={(info) => handleMouseEnter(info)}
                      eventMouseLeave={(info) => handleMouseLeave(info)}
                      eventClick={(info) => handleDateClick(info)}
                      contentHeight="27em"
                      businessHours={[ {daysOfWeek: ['1','2', '3' , '4', '5', '6']} ]}
                      />
                  </Box>
                </Card>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box mt={2}> 
                <Card 
                  align="center"
                  style={{ backgroundColor: '#FFFADF'}}
                  >
                  <Box m={1}>
                    <h4>ПЦР-исследование выполняется<br/> на платной основе<br/>и<br/> 
                    <u>только для выезда за рубеж!</u></h4>
                  </Box>
                </Card>
              </Box>    
              <Box mt={2}> 
                <Card 
                  align="center"
                  style={{ backgroundColor: '#FC3F1D' }}
                  >
                  <Box m={1}>
                    <h4>При повышенной температуре тела забор мазка<br/> <u>не осуществляется!</u></h4>
                  </Box>
                </Card>
              </Box>    
              <Box mt={2}> 
                <Card 
                  align="center"
                  style={{ backgroundColor: '#FFFADF'}}
                >
                  <Box m={1}>
                    <h4>Забор мазка на<br/> ПЦР-исследование проводится<br/> с 9:00 до 10:00</h4>
                  </Box>
                </Card>
              </Box>    
            </Grid>
          </Grid>
        </Container>
      </BrowserView>
      <MobileView>
        <FullCalendar
          locale={
            (ru) 
              ? ruLocale
              : (by)
                ? byLocale
                : enLocale
          }
          plugins={[ interactionPlugin, dayGridPlugin ]}
          headerToolbar={{ 
            left: 'title', 
            center: '',
            right: 'prev,next'
          }}
          initialView="dayGridMonth"
          selectable={false}
          events={scheduledList}
          eventClick={(info) => handleDateClick(info)}
          contentHeight="27em"
          businessHours={[ {daysOfWeek: ['1','2', '3' , '4', '5']} ]}
        />
      </MobileView>
    </>
  )
}
