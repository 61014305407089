import React from 'react'
import moment from 'moment';
import { 
  Stepper, Step, StepLabel, 
  Typography, Container } from '@material-ui/core';

import axiosInstance from './API/axiosAPI'
import fillInScheduledList from './components/functions/fillInScheduledList';

import {
  color,
  step_1_ru, step_1_by, step_1_eng,
  step_2_ru, step_2_by, step_2_eng,
  step_3_ru, step_3_by, step_3_eng,
} from './constants';

import Navbar from './components/src/Navbar';
import WhereToGoPage from './components/pages/WhereToGoPage';
import StateInstitution from './components/src/StateInstitution';
import Calendar from './components/Calendar';
import Form from './components/Form'
import Confirm from './components/Confirm'         
import Footer from './components/src/Footer';

import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import { isBrowser } from 'react-device-detect';

export default function App() {
  const [isShowContacts, setIsShowContacts] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0);
  
  const [selectedDate, setSelectedDate] = React.useState(moment().add(2, 'days').format("YYYY-MM-DD"))
  const [selectedDayOfWeek, setSelectedDayOfWeek] = React.useState('');
  const [dayOfWeek, setDayOfWeek] = React.useState(0)
  const [isSaturday, setIsSaturday] = React.useState(false);
  
  const [gender, setGender] = React.useState('male')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [middleName, setMiddleName] = React.useState('')
  const [birthDate, setBirthDate] = React.useState('')
  const [city, setCity] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [phone, setPhone] = React.useState(' ')
  
  const [ru, setRu] = React.useState(true);
  const [by, setBy] = React.useState(false);
  const [eng, setEng] = React.useState(false);

  const [isWhereToGo, setIsWhereToGo] = React.useState(false);
  
  const steps = getSteps(ru, by, eng);

  const [scheduledList, setScheduledList] = React.useState([])
  
  const [schedule, setSchedule] = React.useState([])

  const getPcrSchedule = async () => {
    await axiosInstance.get(
      'get-pcr-schedule'
      ).then(res => {
        setSchedule(res.data)
        const result = fillInScheduledList(ru, by, eng, res.data)
        setScheduledList(result)
      }).catch((err) => {
        console.log(err)
      });
  }
  
  const createNewScheduledPatient = async (event) => {
    await axiosInstance.post(
      'create-new-scheduled-patient', {
        'date': moment(selectedDate).format('YYYY-MM-DD'),
        'last_name': lastName,
        'first_name': firstName,
        'middle_name': middleName,
        'birth_date': moment(birthDate).format('YYYY-MM-DD'),
        'current_city': city,
        'address': address,
        'phone': phone,
        'gender': gender
      }).then(res => {
        console.log(res.data)
      }).catch((err) => {
        console.log(err)
      });
  }

  const setDayOfWeekName = (day, ru_var, by_var, eng_var) => {
    switch (day) {
      case 1:
        if (ru_var) {
          setSelectedDayOfWeek('Понедельник');
        };
        if (by_var) {
          setSelectedDayOfWeek('Панядзелак');
        };
        if (eng_var) {
          setSelectedDayOfWeek('Monday');
        };
        setIsSaturday(false);
        break;
      case 2:
        if (ru_var) {
          setSelectedDayOfWeek('Вторник');
        };
        if (by_var) {
          setSelectedDayOfWeek('Аўторак');
        };
        if (eng_var) {
          setSelectedDayOfWeek('Tuesday');
        };
        setIsSaturday(false);
        break;
      case 3:
        if (ru_var) {
          setSelectedDayOfWeek('Среда');
        };
        if (by_var) {
          setSelectedDayOfWeek('Серада');
        };
        if (eng_var) {
          setSelectedDayOfWeek('Wednesday');
        };
        setIsSaturday(false);
        break;
      case 4:
        if (ru_var) {
          setSelectedDayOfWeek('Четверг');
        };
        if (by_var) {
          setSelectedDayOfWeek('Чацвер');
        };
        if (eng_var) {
          setSelectedDayOfWeek('Thursday');
        };
        setIsSaturday(false);
        break;
      case 5:
        if (ru_var) {
          setSelectedDayOfWeek('Пятница');
        };
        if (by_var) {
          setSelectedDayOfWeek('Пятніца');
        };
        if (eng_var) {
          setSelectedDayOfWeek('Friday');
        };
        setIsSaturday(false);
        break;
      case 6:
        if (ru_var) {
          setSelectedDayOfWeek('Суббота');
        };
        if (by_var) {
          setSelectedDayOfWeek('Субота');
        };
        if (eng_var) {
          setSelectedDayOfWeek('Saturday');
        };
        setIsSaturday(true);
        break;
    }
  }
  
  const handleGenderChange = () => {
    if (gender === 'male') {
      setGender('female')
    }
    else {
      setGender('male')
    }
  }
  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0, 0);
  };
  
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo(0, 0);
  };
  
  const handleReset = () => {
    getPcrSchedule();
    setActiveStep(0);
    window.scrollTo(0, 0);
  };
  
  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setMiddleName('');
    setBirthDate('');
    setCity('');
    setAddress('');
    setPhone(' ');
  }
  
  const setRussian = () => {
    setRu(true);
    setBy(false);
    setEng(false);
    setDayOfWeekName(dayOfWeek, true, false, false);
    const scheduledListVar = fillInScheduledList(true, false, false, schedule);
    setScheduledList(scheduledListVar);
  }
  
  const setBelarusian = () => {
    setRu(false);
    setBy(true);
    setEng(false);
    setDayOfWeekName(dayOfWeek, false, true, false);
    const scheduledListVar = fillInScheduledList(false, true, false, schedule);
    setScheduledList(scheduledListVar);
  }
  
  const setEnglish = () => {
    setRu(false);
    setBy(false);
    setEng(true);
    setDayOfWeekName(dayOfWeek, false, false, true);
    const scheduledListVar = fillInScheduledList(false, false, true, schedule);
    setScheduledList(scheduledListVar);
  }
  
  function getSteps(ru, by, eng) {
    if (ru) {
      return [step_1_ru, step_2_ru, step_3_ru];
    }
    if (by) {
      return [step_1_by, step_2_by, step_3_by];
    }
    if (eng) {
      return [step_1_eng, step_2_eng, step_3_eng];
    }
  }
  
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <Calendar 
                 handleNext={handleNext}
                 color={color}
                 setSelectedDate={setSelectedDate}
                 setSelectedDayOfWeek={setSelectedDayOfWeek}
                 setIsSaturday={setIsSaturday}
                 scheduledList={scheduledList}
                 fillInScheduledList={fillInScheduledList}
                 setDayOfWeek={setDayOfWeek}
                 setDayOfWeekName={setDayOfWeekName}
                 ru={ru}
                 by={by}
                 eng={eng}
               /> 
      case 1:
        return <Form 
                 handleNext={handleNext} 
                 handleBack={handleBack} 
                 createNewScheduledPatient={createNewScheduledPatient}
                 selectedDayOfWeek={selectedDayOfWeek}
                 selectedDate={selectedDate}
                 isSaturday={isSaturday}
                 firstName={firstName}
                 setFirstName={setFirstName}
                 lastName={lastName}
                 setLastName={setLastName}
                 middleName={middleName}
                 setMiddleName={setMiddleName}
                 birthDate={birthDate}
                 setBirthDate={setBirthDate}
                 city={city}
                 setCity={setCity}
                 address={address}
                 setAddress={setAddress}
                 phone={phone}
                 setPhone={setPhone}
                 gender={gender}
                 handleGenderChange={handleGenderChange}
                 ru={ru}
                 by={by}
                 eng={eng}
                 fillInScheduledList={fillInScheduledList}
               />
      case 2:
        return <Confirm 
                 firstName={firstName}
                 setFirstName={setFirstName}
                 lastName={lastName}
                 setLastName={setLastName}
                 middleName={middleName}
                 setMiddleName={setMiddleName}
                 birthDate={birthDate}
                 gender={gender}
                 setBirthDate={setBirthDate}
                 city={city}
                 setCity={setCity}
                 address={address}
                 setAddress={setAddress}
                 phone={phone}
                 setPhone={setPhone}
                 handleReset={handleReset}
                 selectedDate={selectedDate}
                 clearForm={clearForm}
                 ru={ru}
                 by={by}
                 eng={eng}
               />
      default:
        return 'Unknown stepIndex';
    }
  }
          
  React.useEffect(() => {
    getPcrSchedule();
    if (window.location.pathname.split('/')[1] === 'where-to-go') {
      setIsWhereToGo(true);
    } else {
      setIsWhereToGo(false);
    }
  }, [])

  return (
    <>
      <Router>
        <Switch>
          <Container component="main">
            <Navbar 
              ru={ru} 
              by={by} 
              eng={eng}
              setRussian={setRussian} 
              setBelarusian={setBelarusian} 
              setEnglish={setEnglish}
              isWhereToGo={isWhereToGo}
              setIsWhereToGo={setIsWhereToGo} 
            />
            <Route exact path="/">
              {
                (isBrowser)
                ? <>
                    <StateInstitution 
                      ru={ru} 
                      by={by} 
                    />
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </>
                  : <br /> 
              }
              {activeStep === steps.length ? null : (
                <div>
                  <Typography>{getStepContent(activeStep)}</Typography>
                </div>
              )}
            </Route>
            <Route exact path="/where-to-go">
              <WhereToGoPage ru={ru} by={by} eng={eng}/>
            </Route>
            {/* <Footer 
              isShowContacts={isShowContacts} 
              setIsShowContacts={setIsShowContacts} 
              ru={ru} 
              by={by}/> */}
          </Container>
        </Switch>
      </Router>
    </>
  )
}
