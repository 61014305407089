import { Container, Card, Box, Grid, Button } from "@material-ui/core"

import PublicIcon from '@material-ui/icons/Public';
import EditIcon from '@material-ui/icons/Edit';
import RoomIcon from '@material-ui/icons/Room';

import {
  Link
} from "react-router-dom";

import {
  niiomr_ru, niimor_by, niiomr_eng,
  where_to_go_ru, where_to_go_by, where_to_go_eng,
  make_an_appointment_ru, make_an_appointment_by, make_an_appointment_eng,
} from '../../constants'

export default function Navbar(props) {
  const { ru, by, eng, isWhereToGo } = props
  return (
    <>
      <Container maxWidth="md">
        <Card style={{ backgroundColor: '#F4F5F7' }}>
          <Box  pl={1} pb={1} pr={1}>
            <Grid container spacing={1}>
              <Grid align="left" item md={2} lg={2}>
                {
                  (ru)
                    ? <Button target="_blank" href="https://omr.by" >
                        <PublicIcon/>
                        &nbsp;{niiomr_ru} 
                       </Button>
                    : (by)
                        ? <Button target="_blank" href="https://omr.by/galoynaya" >
                            <PublicIcon/>
                            &nbsp;{niimor_by} 
                          </Button>
                        : <Button target="_blank" href="https://omr.by/home-en" >
                            <PublicIcon/>
                            &nbsp;{niiomr_eng}
                          </Button>
                  }
              </Grid>
              <Grid align="center" item sm={6} md={3} lg={3}>
                {/* <Box pt={1} pl={2}>
                <small>
                  <span style={{ color: '#003896'}}>
                    ПЦР-исследование проводится с <b>8:00</b> до <b>10:00</b>
                  </span>
                </small>
                </Box> */}
              </Grid>
              <Grid align="right" item sm={6} md={4} lg={4}>
                {
                  (isWhereToGo)
                    ? <>
                        <Button 
                          component={Link} 
                          to='/' 
                          onClick={() => props.setIsWhereToGo(false)}
                        >
                          <EditIcon/>&nbsp;
                          {
                            (ru)
                              ? make_an_appointment_ru
                              : (by)
                                  ?  make_an_appointment_by
                                  :  make_an_appointment_eng
                          }
                        </Button>
                      </>
                    : <>
                        <Button 
                          component={Link} 
                          to='/where-to-go/' 
                          onClick={() => props.setIsWhereToGo(true)}
                        >
                          <RoomIcon/>
                          {
                            (ru)
                              ? where_to_go_ru
                              : (by)
                                  ?  where_to_go_by
                                  :  where_to_go_eng
                          }
                        </Button>
                      </>
                }
              </Grid>
              <Grid align="left" item md={3} lg={3}>
                {
                  (ru)
                    ? <Button style={{ color: '#003896' }}><b>RU</b></Button>
                    : <Button onClick={() => props.setRussian()}>RU</Button>
                }
                &nbsp;|&nbsp;
                {
                  (by)
                    ? <Button style={{ color: '#003896' }}><b>BY</b></Button>
                    : <Button onClick={() => props.setBelarusian()}>BY</Button>
                }
                &nbsp;|&nbsp;
                {
                  (eng)
                    ? <Button style={{ color: '#003896' }}><b>ENG</b></Button>
                    : <Button onClick={() => props.setEnglish()}>ENG</Button>
                }
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Container>
    </>
  )
}
