import React from 'react'
import { Button, Card, Box, Container, Grid, Paper, CardContent, CardActions } from '@material-ui/core'
import { YMaps, Map, Placemark } from 'react-yandex-maps';

import { 
  where_to_go_ru, 
  where_to_go_by, 
  where_to_go_eng } from '../../constants';

import logo from '../../img/logo.png'

const mapState = { center: [54.007303, 27.694128], zoom: 16 };
    const placeMark = {
    geometry: [56.848217, 53.236675],
}

export default function WhereToGoPage(props) {
    const { ru, by, eng } = props
    return (
        <>
          <Container component="main" maxWidth='md'>
            <Box align='center' pt={3}>
                <h3>
                {
                    (ru) ? where_to_go_ru
                         : (by) ? where_to_go_by
                                : where_to_go_eng
                }
                </h3>
            </Box>
            <Box>
                <YMaps >
                <Map state={mapState} width="auto" height="18em">
                  {/* 54.006924, 27.696575 */}
                  <Placemark // метка на карте. Чтобы сделать несколько меток Placemark надо поместить в массив
                      key={0}
                      geometry={[54.006924, 27.696575]} // координаты метки
                      properties={{
                        hintContent: `Суда`,
                      }}
                      modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                  />
                </Map>
            </YMaps>
            </Box>
            <Box mb={20}>
            <h4>Как добраться:</h4>
            <b>Государственное учреждение<br/> «Республиканский научно-практический центр онкологии и медицинской радиологии им.Н.Н.Александрова»</b><br/>
	          <br/><b>Адрес:</b> аг. Лесной, Минский район (7 километров от Минской кольцевой дороги по Витебскому направлению)<br/>
            <br/><b>Время приема с 9.00 до 10.00</b>
            <br/><br/><i><u>При обращении обязательно иметь при себе паспорт.</u></i> 
  	        <br/><br/><b>Проезд:</b><br/> 
            <br/>1. общественным транспортом из г.Минска до остановки «Институт онкологии»: 
            <br/>&nbsp;&nbsp;&nbsp;•	от станции метро «Московская»: автобус № 113с; 
            <br/>&nbsp;&nbsp;&nbsp;•	маршрутное такси от площади Я.Коласа (№ 1409).
            <br/><br/>2. общественным транспортом из г.Минска до остановки «Областная больница», далее вверх одна остановка до РНПЦ ОМР им.Н.Н.Александрова: 
            <br/>&nbsp;&nbsp;&nbsp;•	от центрального автовокзала: автобус № 115э (проходит около ст. метро «Площадь Якуба Коласа» и «Московская»);
            <br/>&nbsp;&nbsp;&nbsp;•	от станции метро «Московская»: автобусы № 113с, 145с, 115э;
            <br/>&nbsp;&nbsp;&nbsp;•	маршрутное такси от автостанции «Дружная» (№ 1151), от пл. Я.Коласа (№ 1409).
	          <br/><br/><b>Обращаться:</b> Радиологический 1-этажный корпус, центральный вход прямо и налево.  
            <br/><br/>Оплата производится наличными или безналичным путем по банковской карте.

            </Box>
          </Container>
        </>
    )
}
