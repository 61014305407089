import React from 'react';
import ReactToPdf from 'react-to-pdf'
import { 
  Button, Card, Grid, 
  CardContent, CardActions 
} from '@material-ui/core'
import logo from '../img/logo.png'
import moment from 'moment'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import QRCode from "react-qr-code";
import RoomIcon from '@material-ui/icons/Room';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';


import { 
  state_institution_ru, state_institution_by, state_institution_eng,
  state_institution_name_ru, state_institution_name_by, state_institution_name_eng,
  confirm_appointment_confirmation_ru, confirm_appointment_confirmation_by, 
  confirm_appointment_confirmation_eng,
  confirm_sarscov2_ru, confirm_sarscov2_by, confirm_sarscov2_eng,
  form_first_name_ru, form_first_name_by, form_first_name_eng,
  form_last_name_ru, form_last_name_by, form_last_name_eng,
  form_middle_name_ru, form_middle_name_by, form_middle_name_eng,
  form_birth_date_ru, form_birth_date_by , form_birth_date_eng,
  form_city_ru, form_city_by, form_city_eng,
  form_address_ru, form_address_by, form_address_eng,
  form_phone_ru, form_phone_by, form_phone_eng,
  confirm_we_are_waiting_ru, confirm_we_are_waiting_by, confirm_we_are_waiting_eng,
  confirm_we_are_waiting_at_ru, confirm_we_are_waiting_at_by, confirm_we_are_waiting_at_eng,
  confirm_reset_ru, confirm_reset_by, confirm_reset_eng,
  confirm_download_ru, confirm_download_by, confirm_download_eng,
  where_to_go_ru, where_to_go_by, where_to_go_eng
} from '../constants'


export default function Confirm(props) {
  const {
      firstName, lastName, middleName, birthDate, 
      city, address, phone, ru, by, eng
  } = props

  const ref = React.createRef();
  const options = {
      orientation: 'portrait',
      unit: 'mm',
      format: [340, 180],
      x: 0,
      y: 0
  };

  return (
    <div align="center" >
      <Card 
        style={{ 
          backgroundColor: '#F0F1F3', 
          width: '90mm', 
          heigth: '280mm',
          marginBottom: '3em'
        }} 
      >
        <CardContent 
          ref={ref}
          style={{backgroundColor: 'white'}}
        >
          <div align="center">
            <h5>
              <small>
                {
                  (ru)  ? state_institution_ru
                        : (by)  ? state_institution_by
                                : state_institution_eng
                }
                <br/>
                {
                  (ru)  ? `«${state_institution_name_ru}»`
                        : (by)  ? `«${state_institution_name_by}»`
                                : state_institution_name_eng
                }
              </small>
            </h5>
            <img src={logo} alt="Logo" width="100" height="auto"/>
            <h5>
              <u>
                {
                  (ru)  ? confirm_appointment_confirmation_ru
                          : (by)  ? confirm_appointment_confirmation_by
                                  : confirm_appointment_confirmation_eng
                }
              </u> 
              <br />
              <small>
                {
                  (ru)  ? <>
                            {confirm_sarscov2_ru}
                            <br/>SARS-Cov-2&nbsp;
                            <small>(COVID-19)</small>
                          </>
                        : (by)  ? <>
                                    {confirm_sarscov2_by}
                                    <br/> SARS-Cov-2&nbsp;
                                    <small>(COVID-19)</small>
                                  </>
                                : <>
                                    {confirm_sarscov2_eng}<br/>
                                    <small>(COVID-19)</small> 
                                  </>
                }
              </small>
            </h5>
          </div>
          <div align="left">
            <hr/>
            <small>
              <b>
                {
                  (ru)  ? form_last_name_ru
                        : (by)  ? form_last_name_by
                                : form_last_name_eng
                }
              </b>: {lastName} 
            </small>
            <br/>
            <small>
              <b>
                {
                  (ru)  ? form_first_name_ru
                        : (by)  ? form_first_name_by
                                : form_first_name_eng
                }
              </b>: {firstName} 
            </small>
            <br/>
            <small>
              <b>
                {
                  (ru)  ? form_middle_name_ru
                        : (by)  ? form_middle_name_by
                                : form_middle_name_eng
                }
              </b>: {middleName} 
            </small>
            <br/>
            <small>
              <b>
                {
                  (ru)  ? form_birth_date_ru
                        : (by)  ? form_birth_date_by
                                : form_birth_date_eng
                }
              </b>: {moment(birthDate).format('DD.MM.YYYY')} 
            </small>
            <br/>
            <small>
              <b>
                {
                  (ru)  ? form_city_ru
                        : (by)  ? form_city_by
                                : form_city_eng
                }
              </b>: {city} 
            </small>
            <br/>
            <small>
              <b>
                {
                  (ru)  ? form_address_ru
                        : (by)  ? form_address_by
                                : form_address_eng
                }
              </b>: {address} 
            </small>
            <br/>
            <small>
              <b>
                {
                  (ru)  ? form_phone_ru
                        : (by)  ? form_phone_by
                                : form_phone_eng
                }
              </b>: {phone}
            </small>
            <hr/>
            <small>
              <b>
                {
                  (ru)  ? confirm_we_are_waiting_ru
                        : (by)  ? confirm_we_are_waiting_by
                                : confirm_we_are_waiting_eng
                } 
                <u>{moment(props.selectedDate).format('DD.MM.YYYY')}</u>
                {
                  (ru)  ? confirm_we_are_waiting_at_ru
                        : (by)  ? confirm_we_are_waiting_at_by
                                : confirm_we_are_waiting_at_eng
                } 
                <u>9:00</u>
              </b>
            <hr/>
            <Grid container spacing={1}>
              <Grid align="center" item md={7}>
                <br/>
                <Button>
                  <RoomIcon />&nbsp;    
                    {
                      (ru)  ? where_to_go_ru
                            : (by)  ? where_to_go_by
                                    : where_to_go_eng
                    }
                  &nbsp;<ArrowRightAltIcon />
                </Button>
              </Grid>
              <Grid align="left" item md={5}>
                <QRCode size={80} value={"https://pcr-reg.omr.by/where-to-go/"} />
              </Grid>
            </Grid>
            </small>
          </div>
        </CardContent>
        <CardActions>
          <Grid container spacing={1}>
            <Grid align="left" item xs={6} md={6}>
              <Button 
                onClick={() => {
                  props.handleReset(); 
                  props.clearForm();
                }}
              >
                {
                  (ru)  ? confirm_reset_ru
                        : (by)  ? confirm_reset_by
                                : confirm_reset_eng
                }
              </Button>
            </Grid>                
            <Grid align="center" item xs={6} md={6}>                    
              <ReactToPdf 
                targetRef={ref} 
                filename="appointment_confirmation.pdf"
                options={options}
                scale={2}
              >
                {({toPdf}) => (
                  <Button onClick={toPdf}>
                    <PictureAsPdfIcon />&nbsp; 
                      {
                        (ru)  ? confirm_download_ru
                              : (by)  ? confirm_download_by
                                      : confirm_download_eng
                      }
                  </Button>
                )}
              </ReactToPdf>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </div>
  )
}

