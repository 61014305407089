import React from 'react'
import { Container, Box } from '@material-ui/core'
import logo from '../../img/logo.png'
import {
  state_institution_name_ru, state_institution_name_by, state_institution_name_eng,
  state_institution_ru, state_institution_by, state_institution_eng,
  rmglk_ru, rmglk_by, rmglk_eng,
} from '../../constants';

export default function StateInstitution(props) {
  const { ru, by } = props
  return (
    <>
      <Container component="main" maxWidth="md">
        <div align="center">
          <Box pt={5} mb={-2}>
            <img src={logo} alt="Logo" width="90" height="auto"/>
          </Box>
          <Box pb={2}>
            {
              (ru)
                ? <>
                    <p><small>{state_institution_ru}<br/>«{state_institution_name_ru}»
                    <br/>~<br/>{rmglk_ru}</small></p>
                  </>
                : (by) 
                    ? <>
                        <p><small>{state_institution_by}<br/>«{state_institution_name_by}»
                        <br/>~<br/>{rmglk_by}</small></p>
                      </>
                    : <>
                        <p><small>{state_institution_eng}<br/>«{state_institution_name_eng}»
                        <br/>~<br/>{rmglk_eng}</small></p>
                      </>
            }
          </Box>
        </div>
      </Container>
    </>
  )
}
